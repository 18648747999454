@import '../../styles/shared';

$slick-font-family: 'Font Awesome 5 Free' !default;
$slick-loader-path: '../../assets/images/' !default;
$slick-arrow-color: #000 !default;
$slick-dot-color: #fff !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\f053' !default;
$slick-next-character: '\f054' !default;
$slick-dot-character: '\f111' !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-buttons-offset: -65px;
$slick-buttons-offset-mobile: -40px;
$slick-box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.39);
$slick-transition-time: 0.2s;
$slick-border: 1px solid rgba(0, 0, 0, 0.2);
$slick-border-on-hover: rgba(255, 255, 255, 0.4);

@function slick-image-url($url) {
  @return url($slick-loader-path + $url);
}

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url('ajax-loader.gif') center no-repeat;
  }
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 54px;
  width: 54px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: #fff;
  color: #000;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: $slick-border;
  outline: none;
  transition: all $slick-transition-time;

  @include mq($until: tablet) {
    height: 32px;
    width: 32px;
  }

  &:hover {
    outline: none;
    border: $slick-border-on-hover;
    box-shadow: $slick-box-shadow;

    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }

  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }

  &:before {
    display: block;
    margin-top: 4px;
    font-family: $slick-font-family;
    font-weight: 900;
    font-size: 32px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include mq($until: tablet) {
      font-size: 20px;
    }
  }
}

.slick-prev {
  left: $slick-buttons-offset;

  &:before {
    content: $slick-prev-character;

    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }

  @include mq($until: tablet) {
    left: $slick-buttons-offset-mobile;
  }
}

.slick-next {
  right: $slick-buttons-offset;

  &:before {
    content: $slick-next-character;

    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }

  @include mq($until: tablet) {
    right: $slick-buttons-offset-mobile;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      box-sizing: border-box;
      border: 2px solid #fff;
      border-radius: 50%;
      background: transparent;
      display: block;
      height: 10px;
      width: 10px;
      padding: 0;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      cursor: pointer;

      &:hover,
      &:focus {
        outline: none;

        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 10px;
        height: 10px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        font-weight: 900;
        line-height: 10px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}
